import React from 'react'

const Colores = ({ colores }) => {
    return (
        <div className='d-flex flex-column'>
            <span className='pe-5 fw-bold fs-5'>Cores: </span>
            <div className='mt-2'>
                {colores.map((color, index) => (
                    <div
                    key={index}
                    style={{
                        display: 'inline-block',
                        width: '30px',
                        height: '30px',
                        backgroundColor: color,
                        margin: '5px',
                    }}
                    ></div>
                    ))}
            </div>
        </div>
        );
    };

export default Colores